/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import CampHoc from '../../components/CampHoc';

// eslint-disable-next-line react/prop-types
const CrossCountryTent = ({ data }) => {
  const seo = {
    url: 'https://www.discoverlapland.fi/hiihtovaellukset/aloittelijoiden-hetta-pallas-hiihtovaellus',
    image: `https://www.discoverlapland.fi${data.imageSEO.childImageSharp.gatsbyImageData.images.fallback.src}`,
    title: 'Aloittelijoiden Hetta-Pallas Hiihtovaellus',
    description:
      'Oletko haaveillut hiihtovaelluksesta Lapissa? Liikut tunturisuksilla ahkio perässä valkoisten tuntureiden, rauhan ja avaruuden ympäröimänä. Nukut varaustuvissa ja lähdet virkeänä uuteen päivään samalla, kun lumessa helmeilee miljoonia timantteja ja seuraat, kuinka aika on pysähtynyt legendaarisella Hetta-Pallaksen reitillä Pallas-Yllästunturin kansallispuistossa.',
  };

  return (
    <CampHoc
      heroImage={data.campBg.childImageSharp.gatsbyImageData}
      seo={seo}
      camp="aloittelijoiden-hetta-pallas-hiihtovaellus"
    >
      <div className="camp-text-content">
        <h2>Aloittelijoiden Hetta-Pallas Hiihtovaellus</h2>
        <h3>21.-25.3.2025</h3>
        <p className="bolded">
          Oletko haaveillut hiihtovaelluksesta Lapissa? Liikut tunturisuksilla
          ahkio perässä valkoisten tuntureiden, rauhan ja avaruuden ympäröimänä.
          Nukut varaustuvissa ja lähdet virkeänä uuteen päivään samalla, kun
          lumessa helmeilee miljoonia timantteja ja seuraat, kuinka aika on
          pysähtynyt legendaarisella Hetta-Pallaksen reitillä
          Pallas-Yllästunturin kansallispuistossa.
        </p>
        <br />
        <p>
          Maaliskuussa 2025 päästään jälleen toteuttamaan haaveita, kun naisten
          hiihtovaellus kutsuu hyväkuntoisia hiihtovaelluksesta kiinnostuneita
          aloittelijoita. Itse hiihtovaelluksen kesto on kolme yötä ja neljä
          päivää ja yövymme matkan varrella varaustuvissa. Jos haluat kokeilla
          miltä tuntuu talvitelttailla, tämä on myös mahdollista, otamme teltan
          vaellukselle mukaan.
        </p>
        <br />
        <p>
          Hetta-Pallas hiihtoreitin pituus on noin 60 km. Hiihdämme huolletulla
          latureitillä, joka kulkee kesäreittiä alavampia metsämaita ja soita
          pitkin, joskin Sioskurun ja Hannukurun välillä se sijaitsee
          avotunturissa. Päivämatkat vaihtelevat 13-15 km välillä.
        </p>
        <br />
        <p>
          Tapaamme päivää ennen hiihtovaelluksen alkua Hetassa Hetan
          Majatalossa, jossa olemme yötä. Tällöin tutustumme toisiimme sekä
          käymme kaikki hiihtovaellukseen liittyvät varustukset läpi.
        </p>
      </div>
      <GatsbyImage
        image={data.campImg1.childImageSharp.gatsbyImageData}
        className="offering-img"
        alt="image"
      />
      <div className="camp-text-content">
        <p className="bolded">Hiihtovaelluksen opas</p>
        <p>
          Tällä hiihtovaelluksella oppaana toimii Muoniossa asuva eräopas Sanni
          Lampinen. Luontoa ja kaikkea siellä elävää rakastava Sanni on
          rauhallinen, kaikki huomioon ottava sekä iloinen persoona. Sanni on
          toiselta ammatiltaan fysioterapeutti.
        </p>
        <br />
        <p className="bolded">Ennen retkeä</p>
        <p>
          Lähetämme sinulle tarkan pakkauslistan sekä retkisuunnitelman, jotka
          käymme läpi retkelle lähtijöiden kanssa kahdessa online-tapaamisessa.
        </p>
        <br />
        <p className="bolded">
          Hetta-Pallas hiihtovaelluksen hinta 920€ sis. alv
          <br />
          Hintaan kuuluu:
        </p>
        <p>
          - Opas koko retken ajaksi <br />
          - Retkisuunnitelma sekä turvallisuussuunnitelma <br />
          - Pakkauslista retkelle <br />
          - Ahkio, tunturisukset, monot ja sauvat <br />
          - Kolme yötä varaustuvissa <br />
          - Yksi yö Hetan Majatalossa, johon kuuluu illallinen, sauna ja
          aamiainen ja illalla käymme tarvikkeet läpi ja niiden kanssa
          toimiminen <br />- Kaksi verkkotapaamista (7.2.2025 klo 19-21 ja
          14.3.2025 klo 19-21) ennen retkeä, jossa käymme hiihtovaellukseen
          liittyvät asiat läpi <br />
        </p>
      </div>
      <GatsbyImage
        image={data.campImg2.childImageSharp.gatsbyImageData}
        className="offering-img"
        alt="image"
      />
      <div className="camp-text-content">
        <p className="bolded">Sinulla tulee itselläsi olla:</p>
        <p>
          - Hiihtovaellukseen sopivat vaatteet ja taukokengät <br />
          - 3 vuodenajan makuupussi + makuualusta <br />
          - Retkiruuat <br />
          - Ruokailuvälineet ja astia, juomapullo, termospullo <br />-
          Kuivasäkit, jotka kulkevat ahkiossa ja joissa säilytät tavarasi
        </p>
        <br />
        <p className="bolded">Vaatimustaso:</p>
        <p>
          Kyseessä on aloittelijoiden hiihtovaellus eli sinulla ei tarvitse olla
          hiihtovaelluksesta tai talvivaeltamisesta aikaisempaa kokemusta.
          Fyysisen kunnon puolesta sinun tulee olla hyvässä kunnossa sekä osata
          hiihtää. Matkavauhti on verrattavissa vaeltamiseen eli matkalla
          edetään ahkiota vetäen perinteisen hiihtotyylillä, rauhakseltaan
          edeten.
        </p>
        <br />
        <p className="bolded">
          Ilmoittaudu mukaan sähköpostitse{' '}
          <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>{' '}
          ja toimi näin:
        </p>
        <p>
          Otsikko: Aloittelijoiden Hetta-Pallas hiihtovaellus <br />
          Vastaa meilissä ao. kysymyksiin: <br />
          Koko nimi: <br />
          Ikä: <br />
          Puhelinnumero: <br />
          Paikkakunta: <br />
          Mikä on aikaisempi kokemuksesi luonnossa liikkumisesta? <br />
          Mikä on aikaisempi kokemus hiihtämisestä? <br />
          Mikä on kuntotasosi ja kuina paljon ja miten liikut normaalisti
          arjessa? <br />
          Mikä on terveydentilasi (onko polvi/nivelongelmia, astmaa jne.)?{' '}
          <br />
          Hetan illallista varten: Oletko kasvissyöjä / vegaani?
          <br />
          <br />
          Sinulle tulee itsellesi kustannettavaksi omat kuljetukset Hettaan sekä
          Pallakselta pois kotiisi. Jos olet tulossa autolla, voimme varata
          sinulle auton siirron Hetasta Pallakselle 100€.
          <br />
          <br />
          Retkelle otetaan max 8 naista, vähimmäismäärä on 4hlö, jotta retki
          toteutuu.
          <br /> <br />
          Sinun tulee varata retkeen yksi ylimääräinen yö, jos joudumme yöpymään
          tunturissa yhden yön ylimääräistä säistä johtuen.
        </p>
      </div>
      <div className="offering-img-row">
        <GatsbyImage
          image={data.campImg3.childImageSharp.gatsbyImageData}
          className="offering-img-vert"
          alt="image"
        />
        <GatsbyImage
          image={data.campImg4.childImageSharp.gatsbyImageData}
          className="offering-img-vert"
          alt="image"
        />
      </div>
      <div className="camp-text-content">
        <p className="bolded">Retken varaus- ja peruutusehdot:</p>
        <p>
          Kun olet ilmoittautunut mukaan sähköpostitse, saat paluupostina
          materiaalikululaskun 250€. Kun lasku on maksettu, on paikka
          hiihtovaellukselle varattu ja saat paluupostina hiihtovaelluksen
          materiaalit; pakkauslistan ja retkisuunnitelman sekä
          online-tapaamisten linkit.
          <br />
          <br />
          Hiihtovaelluksen loppulaskun 670€ saat sähköpostiisi hiihtovaelluksen
          aloituspäivänä, jolloin lasku tulee maksaa ja lähettää tästä
          kuittikopio osoitteeseen{' '}
          <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>
          .
          <br />
          <br />
          Jos perut osallistumisesi hiihtovaellukselle missä kohtaa tahansa,
          pidätämme toimitetut hiihtovaelluksen materiaalien kulut sekä
          toimistokulut 250€. Ilmoittautumisen peruutus tulee tehdä
          sähköpostitse osoitteeseen{' '}
          <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>
          .
          <br />
          <br />
          Pidäthän huolen, että sinulla on voimassa oleva vapaa-ajan- tai
          matkavakuutus hiihtovaelluksen aikana. <br />
          <br />
          Tekisikö mieli mukaan tälle upealle hiihtovaellukselle? Mikäli sinulla
          on kysyttävää, voit olla yhteydessä Anetteen joko meilitse
          <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>
          . tai soittamalla 040 772 4124.{' '}
          <Link className="bolded" to="/varaus-ja-peruutusehdot">
            tästä
          </Link>
          . <br />
          <br />
          Tervetuloa mukaan tekemään unohtumaton hiihtovaellus Tunturi-Lappiin
          keväthankien aikaan ja hengittämään maailman puhtainta ilmaa!
        </p>
      </div>
    </CampHoc>
  );
};

export const query = graphql`
  {
    campBg: file(
      relativePath: { eq: "images/crosscountry/ahkio-cabin-7.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg1: file(
      relativePath: { eq: "images/crosscountry/ahkio-cabin-4.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg2: file(
      relativePath: { eq: "images/crosscountry/ahkio-cabin-6.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg3: file(
      relativePath: {
        eq: "images/crosscountry/hettapallas-hiihtovaellus-2023-1.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg4: file(
      relativePath: {
        eq: "images/crosscountry/hettapallas-hiihtovaellus-2023-2.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imageSEO: file(
      relativePath: { eq: "images/crosscountry/ahkio-cabin-4.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1366, layout: FIXED)
      }
    }
  }
`;

export default CrossCountryTent;
